import React from "react";
// import RecommendedCourses from "./RecommendedCourses/RecommendedCourses";
import CoursesWithTitle from "../CoursesWithTitle";
import AvailableBots from "../AvailableBots";

const UserDashboard = ({
  userInfo,
  allCoursesData,
  pageData,
  searchesLeft,
  setSelectedTab,
}) => {
  return (
    <div className="flex flex-col bg-white dark:bg-darkBgColor1">
      <AvailableBots setSelectedTab={setSelectedTab} pageData={pageData} />

      <div className="mt-4">
        {allCoursesData?.length > 0 && (
          <CoursesWithTitle title="Latest Courses" courses={allCoursesData} />
        )}
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 h-full pb-4">
        {/* <PaymentSection userInfo={userInfo} /> */}
        {/* <SearchHistorySection userInfo={userInfo} /> */}
        {/* <UpdateInformationSection
          userInfo={userInfo}
          searchesLeft={searchesLeft}
        /> */}
      </div>
    </div>
  );
};

export default UserDashboard;
