import React, { useContext } from "react";
// import PlayStoreLogo from "../../images/playStoreLogo.svg";
// import AppStoreLogo from "../../images/appStoreLogo.svg";
// import AppStoreLogoDark from "../../images/appStoreLogoDark.png";
import { AppDataContext } from "../../context/appData";
import { Link, useNavigate } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import SocialMediaIcons from "../SocialMediaIcons";
import { fetchPageData } from "../../utils/network-requests";

const Footer = () => {
  const {
    state: { appData },
    initializeAppData,
  } = useContext(AppDataContext);

  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  const { instructorIntroPageData, blogsPageData } = appData;

  // email input
  // const [email, setEmail] = useState("");
  // const [newsletterButtonClicked, setNewsletterButtonClicked] = useState(false);
  // const [showSubscribeSuccessMessage, setShowSubscribeSuccessMessage] =
  //   useState(false);

  // router
  const navigate = useNavigate();

  // handle links click
  const handleNavigation = (path) => {
    if (path.startsWith("/")) {
      navigate(path);
    } else {
      window.location.href = path;
    }
  };

  // const handleSubscribe = (e) => {
  //   e.preventDefault();
  //   if (newsletterButtonClicked) return;
  //   setNewsletterButtonClicked(true);
  //   axios
  //     .post(
  //       getURLs("subscribe-newsletter"),
  //       { email: email },
  //       { withCredentials: true }
  //     )
  //     .then((res) => {
  //       setShowSubscribeSuccessMessage(true);
  //       setEmail("");
  //       setTimeout(() => {
  //         setShowSubscribeSuccessMessage(false);
  //       }, 1000);
  //       setNewsletterButtonClicked(false);
  //     })
  //     .catch((err) => {
  //       setShowSubscribeSuccessMessage(false);
  //       console.log(err);
  //       setNewsletterButtonClicked(false);
  //     });
  // };

  const checkAndFetchInstructorIntroPageData = (path) => {
    if (
      path?.includes("/instructor-intro") &&
      !Object.keys?.(instructorIntroPageData || {})?.length
    ) {
      fetchPageData("instructorIntroPage")
        .then((pageData) => {
          initializeAppData({ instructorIntroPageData: pageData });
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (
      path?.includes("/blogs") &&
      !Object.keys?.(blogsPageData || {})?.length
    ) {
      fetchPageData("blogsPage")
        .then((pageData) => {
          initializeAppData({ blogsPageData: pageData });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="relative flex flex-col px-4 lg:px-16 pb-2 bg-gray32 dark:bg-purple2 pt-8 lg:pt-32">
      {/* top */}
      <div className="flex flex-col md:flex-row md:items-start items-center justify-between mb-4 lg:mb-10">
        {/* left */}
        <div className="flex flex-col items-center md:items-start w-full lg:w-1/4 mb-4">
          {Object.keys(appData).length ? (
            <img
              loading="lazy"
              src={
                darkMode
                  ? appData?.homePageData?.darkModeLogoUrl
                  : appData?.homePageData?.logoUrl
              }
              alt="logo"
              className="w-28 lg:w-32 object-contain mb-5 bg-inherit"
            />
          ) : (
            <span>Loading...</span>
          )}

          <span className="font-poppins font-semibold text-sm dark:text-white text-blue4 block mb-2">
            {appData?.homePageData?.footerSectionData?.aboutHeading}
          </span>
          <p className="font-urbanist font-normal text-sm dark:text-white text-gray10 text-center sm:text-start black mb-4 lg:mb-10 md:max-w-xs">
            {appData?.homePageData?.footerSectionData?.aboutText}
          </p>

          <SocialMediaIcons />
        </div>

        {/* middle */}
        <div className="flex items-start space-x-8 md:space-x-16 md:justify-center">
          {/*rows */}
          {appData?.homePageData?.footerSectionData?.linksData?.map(
            (link, index) => (
              <div className="flex flex-col space-y-4" key={index}>
                <span className="font-urbanist font-semibold text-base dark:text-white text-blue4 block">
                  {link.heading}
                </span>
                {link?.data?.map((value, i) => (
                  <Link
                    key={i}
                    className="font-urbanist font-normal text-sm text-gray30 block cursor-pointer"
                    to={value?.goToLink}
                    target={
                      !value?.goToLink?.startsWith("/") ? "_blank" : "_self"
                    }
                    onMouseEnter={() =>
                      checkAndFetchInstructorIntroPageData(value?.goToLink)
                    }
                  >
                    {value?.text}
                  </Link>
                ))}
              </div>
            )
          )}
        </div>

        {/* right */}
        <div className="hidden lg:flex flex-col items-start w-1/4">
          {/* <span className="font-inter font-semibold text-base text-blue4 block mb-2">
            {appData?.homePageData?.footerSectionData?.getAppText}
          </span> */}
          {/* change */}
          <span className="font-poppins font-semibold text-base dark:text-white text-blue4 block mb-2">
            Get in touch
          </span>
          <p className="block mb-2 font-poppins font-normal text-gray10 text-sm tracking-wide">
            {appData?.homePageData?.footerSectionData?.addressLine1} <br />
            {appData?.homePageData?.footerSectionData?.addressLine2}
            <br />
            {appData?.homePageData?.footerSectionData?.addressLine3}
          </p>
          <div className="flex items-center space-x-2 mb-10">
            {/* google button */}
            {/* <div className="flex items-center px-1 dark:bg-white bg-black rounded-md py-1">
              <img src={PlayStoreLogo} alt="playstore" className="mr-1" />
              <div className="flex flex-col">
                <span className="uppercase dark:text-black text-white text-[8px]">
                  Get it on
                </span>
                <span className="uppercase dark:text-black text-white text-[10px]">
                  Google Play
                </span>
              </div>
            </div> */}

            {/* apple button */}
            {/* <div className="flex items-center px-1 dark:bg-white bg-black rounded-md py-1">
              <img
                src={darkMode ? AppStoreLogoDark : AppStoreLogo}
                alt="playstore"
                className="h-7 w-7 object-contain mr-1"
              />
              <div className="flex flex-col">
                <span className="uppercase dark:text-black text-white text-[8px]">
                  Download on the
                </span>
                <span className="uppercase dark:text-black text-white text-[10px]">
                  App Store
                </span>
              </div>
            </div> */}
          </div>

          {/* newsletter */}
          {/* <span className="font-inter font-semibold text-sm text-blue4 block mb-4">
            News letter
          </span>
          <div className="flex items-center justify-between border-b border-b-gray2 w-full">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={newsletterButtonClicked}
              placeholder="Enter your email address"
              className="flex-grow bg-transparent placeholder:text-blue11 text-blue11 text-xs font-poppins font-normal border-none outline-none py-2 px-1"
            />
            <img
              src={MailIcon}
              alt="mailIcon"
              className="cursor-pointer"
              onClick={handleSubscribe}
            />
          </div>

          {showSubscribeSuccessMessage && (
            <p className="block my-2 text-green-600 text-xs sm:text-sm font-inter font-medium">
              Subscribed to Newsletter
            </p>
          )} */}
        </div>
      </div>

      <div className="w-full border-b-2 border-b-gray-200 mb-4" />
      {/* bottom */}
      <div className="flex flex-col space-y-2 lg:space-y-4 items-center">
        {/* middle */}
        {/* <div className="flex items-center space-x-2 lg:w-1/2 lg:px-14">
          <img src={PhoneIcon} alt="phone" className="" />
          <span className="underline underline-offset-8 font-inter font-medium text-blue11 text-xs lg:text-sm">
            {appData?.homePageData?.footerSectionData?.companyPhone}
          </span>
        </div> */}

        {/* top */}
        {/* <div className="flex items-center lg:w-1/4">
          <span className="font-inter font-medium text-blue11 opacity-60 text-xs lg:text-sm">
            {appData?.homePageData?.footerSectionData?.companyCopyrightText}
          </span>
        </div> */}
        <div className="grid md:grid-cols-4 grid-cols-2 gap-5 text-sm font-urbanist text-gray28">
          <Link
            className="cursor-pointer hover:text-blue-600 transition-all duration-150 ease-in-out"
            to="/privacy-policy"
          >
            Privacy Policy
          </Link>
          <Link
            className="cursor-pointer hover:text-blue-600 transition-all duration-150 ease-in-out"
            to="/cookie-policy"
          >
            Cookie Notice{" "}
          </Link>
          <Link
            className="cursor-pointer hover:text-blue-600 transition-all duration-150 ease-in-out"
            to="/disclosure-agreement"
          >
            Personal Info{" "}
          </Link>
          <Link
            className="cursor-pointer hover:text-blue-600 transition-all duration-150 ease-in-out"
            to="/terms-of-use"
          >
            Terms of Use
          </Link>
        </div>

        {/* bottom */}
        <div className="flex items-center ">
          <span
            className="font-poppins font-medium dark:text-white text-blue11 opacity-60 text-xs lg:text-sm cursor-pointer hover:text-blue-600 transition-all duration-150 ease-in-out"
            onClick={() => navigate("/copyright-policy")}
          >
            {appData?.homePageData?.footerSectionData?.companyCopyrightText}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
