// import node module libraries
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import IntroVideo from "../../videos/steps.mp4";
import { AppDataContext } from "../../context/appData";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
// import PlayStoreLogo from "../../images/playStoreLogo.svg";
// import AppStoreLogoDark from "../../images/appStoreLogoDark.png";
import { getLastWord, getSentenceWithoutLastWord } from "../../utils/utils";

const NewHero = () => {
  // app data context
  const {
    state: { appData },
  } = useContext(AppDataContext);

  // navigate
  const navigate = useNavigate();

  return (
    <section className="py-8 dark:bg-darkBgColor1 bg-white">
      <div className="px-4 lg:px-14">
        <div className="flex flex-col items-center w-full">
          <div className="mb-6 flex flex-col items-center w-full">
            <h5 className="flex items-center justify-center text-center text-sm font-semibold font-poppins mb-4">
              <CheckCircleIcon className="h-4 w-4 text-green-400 rounded-full mr-1" />
              <span
                className="text-center"
                style={{
                  backgroundImage:
                    "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
              >
                {appData?.homePageData?.newHeroSection?.subheading}
              </span>
            </h5>
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-urbanist font-extrabold mb-3 md:!leading-[60px] block tracking-wide dark:text-white max-w-2xl text-center">
              {getSentenceWithoutLastWord(
                appData?.homePageData?.newHeroSection?.heading
              )}
              {` `}
              <span className="text-purple18">
                {getLastWord(appData?.homePageData?.newHeroSection?.heading)}
              </span>
            </h1>
            <p className="block mb-5 font-normal font-urbanist text-sm dark:text-white max-w-lg text-center">
              {appData?.homePageData?.newHeroSection?.description}
            </p>
          </div>
          <div className="flex items-center">
            <video
              className="h-full w-full max-w-lg object-contain mb-4 block rounded-2xl z-10"
              autoPlay={true}
              playsInline
              loop={true}
              muted
              onClick={() => navigate("/login")}
            >
              <source src={IntroVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="flex items-center justify-center space-x-2 mb-10 z-10">
            {/* google button */}
            {/* <div className="flex items-center px-1 bg-white rounded-md dark:border-none border border-gray-400 py-1">
              <img src={PlayStoreLogo} alt="playstore" className="mr-1" />
              <div className="flex flex-col">
                <span className="uppercase text-blue2 text-[8px]">
                  Get it on
                </span>
                <span className="uppercase text-blue2 text-[10px]">
                  Google Play
                </span>
              </div>
            </div> */}

            {/* apple button */}
            {/* <div className="flex items-center px-1 bg-white rounded-md dark:border-none border border-gray-400 py-1">
              <img
                src={AppStoreLogoDark}
                alt="playstore"
                className="h-7 w-7 object-contain mr-1"
              />
              <div className="flex flex-col">
                <span className="uppercase text-blue2 text-[8px]">
                  Download on the
                </span>
                <span className="uppercase text-blue2 text-[10px]">
                  App Store
                </span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewHero;
