import React, { useContext } from "react";
import RenderIcons from "./RenderIcons/RenderIcons";
import { DarkModeContext } from "../../context/darkModeContext";
import { AppDataContext } from "../../context/appData";
import { Link } from "react-router-dom";

const SocialMediaIcons = () => {
  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  const {
    state: { appData },
  } = useContext(AppDataContext);

  const iconsData = appData?.homePageData?.footerSectionData?.iconsData;

  return (
    <div className="flex items-center space-x-2">
      {/* <!-- Facebook --> */}
      {iconsData?.map((icon) => (
        <Link
          target="_blank"
          to={icon?.goToLink}
          key={icon?.iconName}
          className="px-2 py-2 rounded-lg dark:bg-white bg-blue40 flex items-center justify-center cursor-pointer"
        >
          <RenderIcons
            iconName={icon?.iconName}
            iconColor={darkMode ? "#7C51FF" : "#0099FF"}
          />
        </Link>
      ))}
    </div>
  );
};

export default SocialMediaIcons;
